import React from 'react'

const Footer = () => {
  return (
    <div className='footer flex between'>
      <div className='flex'>
        <p className='green-text ml-4'>
        © 2022 OctoHedz Labs
        </p>
      </div>
    </div>
  )
}

export default Footer